import {
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UserState } from '@flink-legacy/core/states/user-state/user.reducers';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[flIfActiveMembership]',
  standalone: true,
})
export class IfActiveMembershipDirective implements OnDestroy {
  private hasView = false;
  private ngUnsubscribe = new Subject<void>();

  private currentMembershipActive$: Observable<boolean> = this.store
    .select(getCurrentUser)
    .pipe(
      takeUntil(this.ngUnsubscribe),
      map(user => user?.current_membership?.status === 'active')
    );

  constructor(
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private store: Store<UserState>
  ) {
    this.currentMembershipActive$.subscribe(isActive => {
      if (isActive) {
        // Do not create a new view if it already exists
        if (!this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        }
      } else {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
