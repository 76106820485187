import { Component, Input, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { TextZoom } from '@capacitor/text-zoom';
import { IonicModule, MenuController } from '@ionic/angular';
import {
  Observable,
  Subject,
  from,
  tap,
  map,
  mergeMap,
  fromEventPattern,
  startWith,
  of,
} from 'rxjs';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from '@bling-fe/shared/modals/base-modal';
import { TabsNavigationModalComponent } from '../tabs-navigation-modal/tabs-navigation-modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UiNotificationsBadgeComponent } from '@bling-fe/shared/ui-components/ui-notifications-badge';
import { MainMenuService } from '../main-menu.service';
import { IfActiveMembershipDirective } from '@flink-legacy/shared/directives/if-active-membership.directive';

@Component({
  selector: 'bling-fe-tabs-navigation',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    SvgIconComponent,
    RouterModule,
    TranslateModule,
    UiNotificationsBadgeComponent,
    IfActiveMembershipDirective,
  ],
  templateUrl: './tabs-navigation.component.html',
  styleUrls: ['./tabs-navigation.component.scss'],
})
export class TabsNavigationComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  private mainMenuItems$ = inject(MainMenuService).items();
  private menuController = inject(MenuController);
  private modalService = inject(ModalService);

  public tabItems$ = this.mainMenuItems$.pipe(
    map(items => [items.NEW, items.HOME, items.CALENDAR, items.COMMUNITY])
  );

  @Input() hide = false;
  hideLabelsIos$: Observable<boolean> = this.hideLabelsIos();

  onNavigate() {
    this.menuController.close();
  }

  toggleMenu() {
    this.menuController.toggle();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async showMoreOptions(event: MouseEvent) {
    event.stopPropagation();

    this.mainMenuItems$.subscribe(async items => {
      const res = await this.modalService.presentAndGetResult(
        TabsNavigationModalComponent,
        {
          tabs: [
            items.MY_PROFILE,
            items.HELP,
            items.SERVICES,
            items.ROOM_BOOKING,
          ],
          modalTitle: _(
            'IHZ_MEMBER.UI_LAYOUT.MENU.TAB_NAV.OPTIONS.MODAL.TITLE'
          ),
        }
      );
      if (!res) {
        return;
      }
    });
  }

  /** Hack for iOS:
   * ios doesn't set the `initial` value of `font-size` in the webview correctly
   * from the system settings based on text zoom.
   * This means the mediaQuery in css (max-width: 20fr) doesn't work there
   * because for mediaqueries the max-width is calculated from the
   * `initial` font-size & not the one in `html` tag.
   */
  private hideLabelsIos(): Observable<boolean> {
    if (Capacitor.getPlatform() === 'ios') {
      return from(TextZoom.getPreferred()).pipe(
        // eslint-disable-next-line no-console
        tap(e => console.log('Zoom', e.value)),
        map(zoom =>
          // Event that triggers when screen width passes 24rem
          window.matchMedia(`(max-width: ${24 * 16 * zoom.value}px)`)
        ),
        mergeMap(mediaQuery =>
          // why fromEventPattern? https://github.com/ReactiveX/rxjs/issues/4749
          fromEventPattern<MediaQueryListEvent>(
            mediaQuery.addListener.bind(mediaQuery),
            mediaQuery.removeListener.bind(mediaQuery)
          ).pipe(startWith(mediaQuery))
        ),
        // eslint-disable-next-line no-console
        tap(e => console.log('Matches', e.matches)),
        // true if screen width is less than 24rem
        map(e => e.matches)
      );
    } else {
      return of(false);
    }
  }
}
