<ion-tab-bar
  [style.display]="hide ? 'none' : 'flex'"
  mode="md"
  class="hide-labels-if-display-small"
  [class.hide-labels]="hideLabelsIos$ | async"
>
  <ion-tab-button
    *ngFor="let item of tabItems$ | async"
    (click)="item.onClick ? item.onClick() : null"
    [routerLink]="item.url ? [item.url] : []"
    routerLinkActive="router-link-active"
    [class.with-router-link]="item.url"
  >
    <svg-icon
      [key]="item.icon"
      size="xl"
    ></svg-icon>
    <ion-label>
      {{ item.label | translate }}
    </ion-label>
    <ng-container *flIfActiveMembership>
      <bl-notifications-badge
        *ngIf="item.notificationCount$ | async as notificationsCount"
        size="sm"
        [count]="notificationsCount"
      ></bl-notifications-badge>
    </ng-container>
  </ion-tab-button>
  <ion-tab-button (click)="showMoreOptions($event)">
    <svg-icon
      [key]="'main-menu-more'"
      size="xl"
    ></svg-icon>
    <ion-label>
      {{ "IHZ_MEMBER.UI_LAYOUT.MENU.TAB_NAV.OPTIONS.TITILE" | translate }}
    </ion-label>
  </ion-tab-button>
</ion-tab-bar>
