import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuService, MenuItemDetail } from '../main-menu.service';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UiNotificationsBadgeComponent } from '@bling-fe/shared/ui-components/ui-notifications-badge';
import { TranslateModule } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { getCurrentUser } from '@flink-legacy/core/states/user-state/user.selectors';
import { UiAvatarComponent } from '@bling-fe/shared/ui-components/ui-avatar';
import { IfActiveMembershipDirective } from '@flink-legacy/shared/directives/if-active-membership.directive';

@Component({
  selector: 'bling-fe-main-menu-item',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    SvgIconComponent,
    UiNotificationsBadgeComponent,
    TranslateModule,
    UiAvatarComponent,
    IfActiveMembershipDirective,
  ],
  template: `
    <ng-container [ngSwitch]="itemType">
      <ion-item
        *ngSwitchCase="'internalLink'"
        [routerLink]="item.url"
        routerLinkActive="router-link-active"
        [detail]="false"
        button
      >
        <svg-icon
          [key]="item.icon"
          size="xl"
          slot="start"
        ></svg-icon>
        <ion-label>
          {{ item.label | translate }}
        </ion-label>
        <ng-container *flIfActiveMembership>
          <bl-notifications-badge
            *ngIf="item.notificationCount$ | async as notificationCount"
            slot="end"
            class="ml-4"
            [count]="notificationCount"
          />
        </ng-container>
      </ion-item>

      <ion-item
        *ngSwitchCase="'externalLink'"
        [href]="item.url"
        target="blank"
        [detail]="false"
        button
      >
        <svg-icon
          [key]="item.icon"
          size="xl"
          slot="start"
        ></svg-icon>
        <ion-label>
          {{ item.label | translate }}
        </ion-label>
      </ion-item>

      <ion-item
        *ngSwitchCase="'onClick'"
        (click)="item.onClick!()"
        target="blank"
        [detail]="false"
        button
      >
        <svg-icon
          [key]="item.icon"
          size="xl"
          slot="start"
        ></svg-icon>
        <ion-label>
          {{ item.label | translate }}
        </ion-label>
      </ion-item>

      <ng-container *ngSwitchCase="'myProfile'">
        <ion-item
          *ngIf="currentUser$ | async as user"
          [routerLink]="'/my-profile'"
          class="my-profile"
          routerLinkActive="router-link-active"
          [detail]="false"
          button
        >
          <bl-avatar
            [user]="user"
            [size]="36"
            [clickable]="false"
            slot="start"
          ></bl-avatar>
          <ion-label>
            {{ user.full_name }}
          </ion-label>
        </ion-item>
      </ng-container>
    </ng-container>
  `,
  styles: [
    `
      ion-item {
        --min-height: 40px;
        --inner-padding-start: 1rem;
        --inner-padding-end: 20px;
        --padding-start: 60px;
        --padding-bottom: 5px;
        font-size: var(--font-base);
        line-height: var(--line-height-base);
        font-weight: var(--font-weight-medium);

        --background-hover: var(--ion-color-primary-highlight);
        --background-hover-opacity: 1;
        --color-hover: var(--ion-color-primary);

        &.my-profile {
          --padding-top: 3px;
          --padding-bottom: 3px;
          // center the profile picture vertically
          // 36 px = avatar size, 24 px = icon size
          // additional -2px to align with figma, it looks better, not sure why, in total -8px
          // inner padding adjusted with formula: 20px - 8px = 12px
          --inner-padding-start: 12px;
          margin-left: -((36px - 24px) / 2) - 2px;
        }

        &.router-link-active {
          font-weight: bold;
          --color: var(--ion-color-primary);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuItemComponent {
  private store = inject(Store);
  private mainMenuService = inject(MainMenuService);

  @Input() item!: MenuItemDetail;

  currentUser$ = this.store.pipe(select(getCurrentUser));

  public get itemType() {
    if (this.item === this.mainMenuService.STATIC_ITEMS.MY_PROFILE)
      return 'myProfile';
    if ('onClick' in this.item) return 'onClick';
    return this.item.url?.startsWith('/') ? 'internalLink' : 'externalLink';
  }
}
